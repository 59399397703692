import { ActionTree } from "vuex";
import { RootState } from "../types";
import { StoreState, Organization } from "./internalTypes";
import { internalAPI } from "@/lib/s3PlatformApi";
import { CreateReport } from "@/lib/serviceTypes";

export enum Actions {
  GET_PROJECTS_FOR_ORGNAIZATION = "GET_PROJECTS_FOR_ORGNAIZATION",
  GET_ASSESSMENTS_FOR_ORGANIZATION = "GET_ASSESSMENTS_FOR_ORGANIZATION",
  GET_ASSESSSMENTS_FOR_PROJECT = "GET_ASSESSSMENTS_FOR_PROJECT",
  GET_PROJECT_REPORTS = "GET_PROJECT_REPORTS",
  GET_ORGANIZATIONS = "GET_ORGANIZATIONS",
  GET_BENCHMARKS_FOR_PROJECT = "GET_BENCHMARKS_FOR_PROJECT",
  GET_REPORT = "GET_REPORT",
  UPDATE_REPORT = "UPDATE_REPORT",
  DELETE_REPORT = "DELETE_REPORT",
  SAVE_PROJECT_REPORT = "SAVE_PROJECT_REPORT",
}

/*
function Result<O, T extends { results: O[] }>(resp: AxiosResponse<T, any>) {
  return resp.data.results;
}*/

export const actions: ActionTree<StoreState, RootState> = {
  [Actions.GET_PROJECTS_FOR_ORGNAIZATION]: async (_, orgId: string) => {
    return (await internalAPI.getManyProjects({ org_id: orgId, page_size: 999 })).data.results;
  },
  [Actions.GET_PROJECT_REPORTS]: async () => {
    return [];
  },
  [Actions.GET_BENCHMARKS_FOR_PROJECT]: async (_, projectId: string) => {
    return (await internalAPI.getManyBenchmarks({ project: projectId, page_size: 999 })).data.results;
  },
  [Actions.GET_ORGANIZATIONS]: async () => {
    return (await internalAPI.getOrganizations()).data as Organization[];
  },
  [Actions.GET_ASSESSSMENTS_FOR_PROJECT]: async (_, projectId: string) => {
    return (await internalAPI.getManyAssessments({ project: projectId, include_scores: true, page_size: 999 })).data.results;
  },
  [Actions.GET_ASSESSMENTS_FOR_ORGANIZATION]: async (_, organization: string) => {
    return (await internalAPI.getManyAssessments({ organization, include_scores: true, page_size: 999 })).data.results;
  },
  [Actions.SAVE_PROJECT_REPORT]: async (_, { projectId, projectReport }: { projectId: string; projectReport: CreateReport }) => {
    const response = await internalAPI.createProjectReport(projectId, projectReport);

    if (response.status !== 201) {
      throw new Error(`Failed to save report: ${JSON.stringify(response.data)}`);
    }

    return response.headers["x-identifier"];
  },
  [Actions.GET_REPORT]: async (_, reportId: string) => {
    return (await internalAPI.getReport(reportId)).data;
  },
  [Actions.UPDATE_REPORT]: async (_, data: { reportId: string; name: string; description: string | undefined; assessmentIds: string[]; data: Record<string, unknown> }) => {
    return await internalAPI.updateReport(data.reportId, {
      name: data.name,
      description: data.description,
      assessmentIds: data.assessmentIds,
      data: data.data,
    });
  },
  [Actions.DELETE_REPORT]: async (_, reportId: string) => {
    return await internalAPI.deleteReport(reportId);
  },
};
