
import OrganizationSelectModal from "@/components/admin/OrganizationSelectModal.vue";
import EditProjectModal from "@/components/admin/EditProjectModal.vue";
import Component from "vue-class-component";
import { Actions } from "@/store/admin/adminActions";
import { Getters } from "@/store/admin/adminGetters";
import { V2Project } from "@/store/admin/adminTypes";
import { TypedVue } from "@/store/types";
import { Action, Getter } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { Roles } from "@/lib/auth/User";

const namespace = "admin";

@Component({
  components: {
    OrganizationSelectModal,
    EditProjectModal,
  },
})
export default class TopHeader extends TypedVue {
  @Action(Actions.GET_ORG_PROJECTS, { namespace })
  getProjects!: () => Promise<V2Project[]>;

  @Action(Actions.CHANGE_PROJECT, { namespace })
  changeProject!: (project: V2Project | undefined) => Promise<void>;

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | undefined;

  @Getter(Getters.GET_PROJECTS, { namespace })
  public projects!: V2Project[];

  @Prop({ default: true })
  public fetchData!: boolean;

  public orgModalVisible = false;
  public projectModalVisible = false;
  public currentOrganizationId: string = this.$auth.user?.currentOrganization?.id || "";

  public animationClass = "";
  public cornerAnimationClass = "";

  get organizations() {
    return this.$auth.user?.organizations.map((x) => ({ value: x.id, text: x.display_name })) || [];
  }

  get currentOrganization() {
    return this.organizations.find((x) => x.value === this.currentOrganizationId) || "";
  }

  get userName() {
    return this.$auth.user?.name || "";
  }

  get userIsGlobalAdmin() {
    return this.$auth.user?.hasRole(Roles.GLOBAL_ADMIN) || false;
  }

  get userIsOrgAdmin() {
    return this.$auth.user?.hasRole(Roles.ORGANIZATION_ADMIN) || false;
  }

  get showOrgSelector() {
    return this.organizations.length > 1 && !this.$auth.user?.hasRole(Roles.CANDIDATE);
  }

  get routeMetaOptions() {
    return this.$route.meta?.header || {};
  }

  get showProjectSelector(): boolean {
    return (this.routeMetaOptions.showProjectSelector || false) && this.currentProject;
  }

  get showProjectSettings(): boolean {
    return this.showProjectSelector && (this.userIsGlobalAdmin || this.userIsOrgAdmin);
  }

  get links() {
    return this.routeMetaOptions.links || [];
  }

  get fetchDataOnMount() {
    return this.routeMetaOptions.fetchDataOnMount !== undefined ? this.routeMetaOptions.fetchDataOnMount : true;
  }

  get sortedProjects() {
    return this.projects.sort((a, b) => (b.lastUpdated || "").localeCompare(a.lastUpdated || ""));
  }

  @Watch("links")
  onLinksChange(newValue: any[] | undefined, oldValue: any[] | undefined) {
    this.setAnimationClass(newValue, oldValue);
  }

  async mounted() {
    this.setAnimationClass(this.links, undefined);
    if (this.fetchDataOnMount) {
      await this.getProjects();

      if (this.$route.query.project) {
        const projIndex = this.projects.findIndex((p) => p.identifier === this.$route.query.project);
        if (projIndex === -1) {
          await this.selectProject(undefined);
        } else {
          await this.selectProject(this.projects[projIndex]);
        }
        // Remove project id from url
        this.$router.replace("/admin/summary");
      } else {
        if (!this.currentProject) {
          await this.selectProject(undefined);
        }
      }
    }
  }

  async selectProject(project: V2Project | undefined) {
    const newProject = await this.changeProject(project);
    this.$emit("selectProject", newProject);

    if (this.$route.path !== "/admin/summary") {
      this.$router.push("/admin/summary");
    }
  }

  closeProjectModal() {
    this.projectModalVisible = false;
  }

  closeOrgModal() {
    this.orgModalVisible = false;
  }

  openProjectModal() {
    this.projectModalVisible = true;
  }

  openOrgModal() {
    this.orgModalVisible = true;
  }

  logOut() {
    this.$auth.logout({ returnTo: window.location.origin });
  }

  setAnimationClass(newLinks: any[] | undefined, oldLinks: any[] | undefined) {
    if (newLinks && newLinks.length > 0) {
      this.animationClass = "slide-in";
      this.cornerAnimationClass = "to-link-colour";
    } else if (oldLinks && oldLinks.length > 0 && (!newLinks || newLinks.length === 0)) {
      this.animationClass = "slide-out";
      this.cornerAnimationClass = "to-header-colour";
    } else {
      this.animationClass = "hidden";
      this.cornerAnimationClass = "normal-colour";
    }
  }
}
